import { ref, computed } from 'vue'
import { ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useExtensions } from '/~/composables/extensions'
import { useLocalization } from '/~/composables/localization'
import {
  PointsPartner,
  PointsPartnerResponse,
} from '/~/templates/bill-payments/core/points-partner'

interface PointsProgram {
  enabled: boolean
  type: string
  name: string
  accountNumberLabel: string
  conversionRate: string
  minTransferLimit?: number
  maxTransferLimit?: number
  registrationUrl?: string
  description?: string
  api: string
  programCurrency?: string
}

const { getConfigByName } = useExtensions()
const { airlinePartnersTerms, airlinePartnersIcon } = useCms()
const { translate } = useLocalization()

const pointsProgramConfig = computed(() => {
  return getConfigByName('points-program')
})

const accounts = ref<PointsPartner[]>([])
const fetchAccountsLoading = ref(false)

const pointsPrograms = computed<PointsProgram[]>(() => {
  return pointsProgramConfig.value?.pointsPrograms ?? []
})

const partners = ref<PointsProgram[]>([])

function initAirPartners() {
  partners.value = pointsPrograms.value.map((program) => ({
    icon: airlinePartnersIcon(program.type),
    addNewDescription: `Enter the ${program.accountNumberLabel} associated with your ${program.name} account and be able to transfer your ${program.programCurrency}.`,
    ...program,
  }))
}

const selectedPartner = ref({})
const transfer = ref<PointsProgramOrderCheckout | null>(null)

const selectedPartnersTerms = computed(() =>
  airlinePartnersTerms(selectedPartner.value.type)
)
const termsLabel = computed(
  () => selectedPartnersTerms.value.label || 'Terms & Conditions'
)

function findPartner(partnerType) {
  if (!partnerType) return
  return partners.value.find((partner) => partner.type === partnerType)
}

function selectPartner(partnerType) {
  selectedPartner.value = findPartner(partnerType)
}

async function fetchAccounts() {
  fetchAccountsLoading.value = true
  const { data } = await api.get<ApiResponseData<PointsPartnerResponse[]>>(
    '/v3/points-programs/accounts'
  )

  accounts.value = data
    .filter((ac) => partners.value.some((i) => i.type === ac.type))
    .map((ac) => new PointsPartner(ac))
  fetchAccountsLoading.value = false
}

function getAccountById(id) {
  return accounts.value.find((item) => item.id == id)
}

interface PointsProgramsAaccount {
  firstName?: string
  lastName?: string
  accountNumber: string
  type: string
}

async function createAccount(payload: PointsProgramsAaccount) {
  const { data } = await api.post<ApiResponseData<PointsPartnerResponse>>(
    '/v3/points-programs/accounts',
    payload
  )
  const newAccount = new PointsPartner(data)

  accounts.value.push(newAccount)
  return newAccount
}

async function deleteAccount(id) {
  await api.delete(`/v3/points-programs/accounts/${id}`)
  const accountIndex = accounts.value.findIndex((account) => account.id === id)

  if (accountIndex > -1) {
    accounts.value.splice(accountIndex, 1)
  }
}

async function verifyBusinessNumber(payload, options) {
  try {
    return api.post(
      '/v3/points-programs/account-details-verify',
      payload,
      options
    )
  } catch (error) {
    const data = error.data || error

    if (data.status === 500) {
      data.message = `Error while verifying ${translate(
        'payment.businessNumber'
      )}. Please try again later.`
    }

    throw new Error(data.message)
  }
}

interface PointsCalculateRawData {
  amount: number
  orderType: string
  points: number
  usedRatio: number
}

interface PointsProgramsCalculateRawData {
  amount: string
  program: string
  programPoints: number
}

interface CalculatePointsPayload {
  amount?: string | number
  points?: string | number
  type?: FlowType
}

async function calculatePoints(options: CalculatePointsPayload) {
  const { type } = options
  let amount = options.amount

  if (!amount) {
    const response = await api.post<ApiResponseData<PointsCalculateRawData>>(
      '/v3/points/calculate',
      {
        orderType: FlowType.pointsProgramOrder,
        pointsProgramType: type,
        points: Number(options.points),
      },
      { notify: false }
    )

    amount = response.data.amount
  }

  try {
    return api.post<ApiResponseData<PointsProgramsCalculateRawData>>(
      `/v3/points-programs/${type}/calculate`,
      {
        amount,
      }
    )
  } catch (error) {
    throw new Error(error.message)
  }
}

interface PaymentSource {
  paymentMethodId: string
  amount: string
}

interface PointsProgramPayload {
  externalId: string
  programType: string
  amount: string
}

interface PaymentPayload {
  paymentSources: PaymentSource[]
  pointsPrograms: PointsProgramPayload[]
  addressId: string
  reference: string
}

interface PointsProgramOrderCheckout {
  number: string
  status: string
  completedAt: string
  subtotal: string
  fee: string
  total: string
}

interface Partner {
  accountNumber: string
  externalId: string
  firstName: string
  lastName: string
  type: string
}

interface TransferPointsForm {
  eWalletPoints: string
  flyPoints: string
  partner: Partner
}

async function transferPoints(payload: {
  payload: PaymentPayload
  form: TransferPointsForm
}) {
  try {
    const response = await api.post<
      ApiResponseData<PointsProgramOrderCheckout>
    >('/v3/points-program-orders/checkout', payload.payload)

    transfer.value = { ...response.data, ...payload.form }
    emitter.emit('activity:refresh')
    return response
  } catch (error) {
    const msg = error.data?.message ?? error.message

    throw new Error(msg)
  }
}

function showTerms() {
  if (selectedPartnersTerms.value.terms_and_conditions.type === 'input') {
    window.open(
      selectedPartnersTerms.value.terms_and_conditions.input,
      '_blank'
    )
  } else {
    modal.show('points-transfer-terms')
  }
}

function getAccountFullName(account) {
  const firstName = account?.firstName
  const lastName = account?.lastName

  return firstName !== lastName && lastName
    ? `${firstName} ${lastName}`
    : firstName
}

export function usePointsPrograms() {
  return {
    accounts,
    partners,
    transfer,
    termsLabel,
    selectedPartnersTerms,

    initAirPartners,
    selectedPartner,
    selectPartner,
    fetchAccountsLoading,
    fetchAccounts,
    createAccount,
    deleteAccount,
    verifyBusinessNumber,
    calculatePoints,
    transferPoints,
    findPartner,
    getAccountById,
    showTerms,
    getAccountFullName,
  }
}
